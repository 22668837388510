<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    class="custom-task-form"
    @save="onClickSave"
    @cancel="onCancel('CustomPeriodicTasks')"
  >
    <template v-slot:times="{ field }">
      <p class="editable-form__description">{{ field.label }}</p>
      <div class="times">
        <div
          v-for="time in form.times"
          :key="time.id"
          class="times__row"
        >
          <sm-datepicker
            v-model="time.time"
            type="time"
            format="HH:mm"
            valueType="HH:mm"
            timeTitleFormat="HH:mm"
          />
          <button
            class="editable-list__datatable-button editable-list__datatable-button--delete"
            @click="onDeleteTime(time.id)"
          >
            <sm-icon name="trash" />
          </button>
        </div>
        <sm-button
          :disabled="isCreateTimeButtonDisabled"
          class="times__create-button"
          @click="onCreateTime"
        >
          <sm-icon name="plus" />
          Добавить
        </sm-button>
      </div>
    </template>
  </sm-editable-item>
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import smDatepicker from '@/components/common/forms/SmDatepicker.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';
import SmIcon from '@/components/common/SmIcon.vue';

export default {
  name: 'CustomPeriodicTaskCreate',

  components: {
    SmEditableItem,
    smDatepicker,
    SmButton,
    SmIcon,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'CustomPeriodicTasks',
      pageHeader: 'Создание кастомной периодической задачи',
      breadcrumbs: [
        {
          text: 'Публикация приложений',
          route: { name: 'PublishingApplicationsMain' },
        },
        {
          text: 'Кастомные периодические задачи',
          route: { name: 'CustomPeriodicTasks' },
        },
        {
          text: 'Создание кастомной периодической задачи',
        },
      ],
      initialForm: {
        name: null,
        folder: null,
        isEnabled: false,
        scheduleMode: null,
        scheduledTime: null,
        arguments: [],
        times: [],
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      customPeriodicTaskModes: (state) => state.common.customPeriodicTaskModes,
    }),

    newTimeRowId() {
      const { times } = this.form;
      if (!times?.length) return 1;

      const lastExistId = Math.max(...times.map((time) => time.id));
      return lastExistId + 1;
    },

    isCreateTimeButtonDisabled() {      
      const { times } = this.form;

      return times?.some(item => !item.time);
    },

    fields() {
      const scheduleMode = this.customPeriodicTaskModes.data.find(
        (item) => item.id === this.form.scheduleMode
      );
      const fields = [
        {
          tab: 'Основные',
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Название задачи',
            },
            {
              type: 'text',
              key: 'folder',
              label:
                'Имя папки задачи (запрещены символы [\\,:], т.к. это будет физическая папка)',
            },
            {
              type: 'checkbox',
              key: 'isEnabled',
              label: 'Активная',
            },
            {
              type: 'fullDateTime',
              key: 'scheduledTime',
              label:
                'Дата и время первого запуска, и время для последующих запусков',
            },
            {
              type: 'select',
              key: 'scheduleMode',
              label: 'Периодичность',
              list: this.customPeriodicTaskModes.data,
            },
            {
              type: 'integerNumber',
              key: 'scheduleModeArgument',
              label: scheduleMode?.argumentName,
              hidden: !scheduleMode?.required,
            },
            {
              type: 'html',
              key: 'times',
              label: 'Время запуска',
              hidden: scheduleMode?.id !== 6,
            },
          ],
        },
        {
          tab: 'Параметры задачи',
          table: {
            caption: 'Параметры задачи',
            headers: [
              {
                text: 'Название аргумента',
                alias: 'name',
              },
              {
                text: 'Значение аргумента',
                alias: 'value',
              },
              {
                alias: 'actions',
              },
            ],
            items: this.form.arguments,
            key: 'arguments',
            modalFields: [
              {
                type: 'text',
                key: 'name',
                label: 'Название аргумента',
              },
              {
                type: 'text',
                key: 'value',
                label: 'Значение аргумента',
              },
            ],
            showCreateButton: true,
            showEditButton: true,
            showDeleteButton: true,
          },
        },
      ];

      fields[0].form = fields[0].form.filter((field) => !field.hidden);

      return fields;
    },
  },

  watch: {
    'form.scheduleMode': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== undefined) {
          const selectedScheduleModeOption =
            this.customPeriodicTaskModes.data.find(
              (item) => item.id === this.form.scheduleMode
            );

          if (!selectedScheduleModeOption?.required) {
            this.form.scheduleModeArgument = null;
          }

          if (newValue !== 6) {
            this.form.times = [];
          }
        }
      },
    },
  },

  created() {
    this.getCommonList({ name: 'CustomPeriodicTaskModes' });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),

    onCreateTime() {
      this.form.times.push({
        id: this.newTimeRowId,
        time: '',
      })
    },

    onDeleteTime(id) {
      this.form.times = this.form.times.filter(item => item.id !== id);
    },

    onClickSave() {
      this.form.times = this.form.times.filter(item => !!item.time);

      this.onSave('CustomPeriodicTasks');
    },
  },
};
</script>

<style lang='scss'>
.custom-task-form .editable-form__column {
  align-items: center;
}

.custom-task-form .times {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  width: 100%;
}

.custom-task-form .times__row {
  display: flex;
  gap: 5px;
}

.custom-task-form .times__create-button {
  width: fit-content;
}

.custom-task-form .times__create-button:disabled {
  cursor: default;
}
</style>
